<template>
  <div class="container">
    <!-- <casetable></casetable> -->
    <el-row>
      <el-col :span="24">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <h3>案例管理</h3>
          <div>
            <el-select
              v-model="project_type"
              size="small"
              placeholder="按项目类型筛选"
              clearable
              style="margin-right: 10px"
            >
              <el-option
                v-for="item in projectTypes"
                :key="item.id"
                :label="item.project_name"
                :value="item.id"
              ></el-option>
            </el-select>

            <el-date-picker
              v-model="date_range"
              size="small"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>

            <el-button
              size="small"
              style="background-color: #29303e; margin-left: 10px; color: white"
              @click="search"
            >
              查询
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <div style="border-bottom: 1px solid #bbb; margin: 20px 0"></div>
    <div>
      <el-table
        size="small"
        :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
        :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
        :data="rows"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号"></el-table-column>

        <el-table-column prop="project_id" label="项目ID"></el-table-column>
        <el-table-column prop="project_name" label="项目名称"></el-table-column>
        <el-table-column prop="project_types" show-overflow-tooltip label="项目类型">
          <template slot-scope="scope">
            {{ projectTypeName(scope.row.project_types) }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="所属用户"></el-table-column>
        <el-table-column prop="created_at" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDate }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="updated_at" label="更新时间">
          <template slot-scope="scope">
            {{ scope.row.updated_at | formatDate }}
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="view(scope.row.id)" v-permission:view_programme>
              查看
            </el-button>
            <el-button type="text" @click="edit(scope.row.id)" v-permission:modidy_programme>
              编辑
            </el-button>
            <!-- <el-button
                            type="text"
                            @click="dispatch(scope.row.id)"
                            v-permission:dispatch_programme
                        >
                            分发
                        </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        v-if="pagination"
        :page-size="15"
        @current-change="currentChange"
        layout="prev, pager, next"
        :total="pagination.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { programmeList, programmeProjectType } from '@/common/programme.js';

export default {
  data() {
    return {
      rows: [],
      pagination: null,
      project_type: null,
      date_range: null,
      projectTypes: [],
      projectTypeMap: {},
    };
  },
  async created() {
    let resp = await programmeProjectType();
    // console.log(resp.result);
    this.projectTypes = resp.result;

    for (let r of resp.result) {
      this.projectTypeMap[r.id] = r.project_name;
    }

    await this.load();
  },
  methods: {
    async load(page = 1) {
      let params = {
        page,
        project_type: this.project_type,
      };
      if (this.date_range) {
        params['date_realm'] = JSON.stringify(this.date_range);
      }
      let resp = await programmeList(params);
      console.log(resp);
      this.rows = resp.result.data;
      this.pagination = {
        current_page: resp.result.current_page,
        per_page: resp.result.per_page,
        total: resp.result.total,
      };
    },
    projectTypeName(val) {
      let types = val.split(',');
      let typeNames = [];
      for (let type of types) {
        typeNames.push(this.projectTypeMap[type] ?? '');
      }
      return typeNames.join(',');
    },
    async currentChange(page) {
      await this.load(page);
    },
    edit(id) {
      this.$router.push({
        path: 'programmeEdit',
        query: {
          id: id,
        },
      });
    },
    view(id) {
      this.$router.push({
        path: 'programmeView',
        query: {
          id: id,
        },
      });
    },
    async search() {
      await this.load(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px;
  text-align: center;
}
</style>
